'use client'

import { Box } from '@design-system/src/components/Box'
import { Button } from '@design-system/src/components/Button'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { getStripe } from '@design-system/src/utils/stripe-utils'
import { loadStripe } from '@stripe/stripe-js/pure'
import { CreateCheckoutSessionForGiftBody } from 'app/api/stripe/create-checkout-session-for-gift/route'
import { FC, useCallback, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import { css, cva } from 'styled-system/css'

import { postData } from '../../../_utils/rest-utils'
import { BlockPubGiftCustomPageData } from '../../../_utils/typescript-utils'
import { DisplayErrorToUser } from './DisplayErrorToUser'
import { GetUserEmail } from './GetUserEmail'

export const StarredPlan: FC<
  NonNullable<BlockPubGiftCustomPageData['starredGiftPlan']> & {
    userEmail?: string
    stripePublishableKey: string
    customerServiceNumber?: string
    customerServiceNumberURI?: string
  }
> = ({
  planSummary,
  benefits,
  name,
  subtitle,
  priceInCents,
  monthsGifted,
  publicationName,
  userEmail,
  priceId,
  stripePublishableKey,
  customerServiceNumber,
}) => {
  const [userInfoModalIsOpen, setUserInfoModalIsOpen] = useState(false)
  const [callInProgress, setCallInProgress] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)

  const purchaseGiftPlan = useCallback(async ({ email }: { email: string }) => {
    try {
      setCallInProgress(true)
      const { accountId, sessionId } = await postData<
        CreateCheckoutSessionForGiftBody,
        {
          sessionId: string
          accountId: string
        }
      >({
        url: API_ROUTES_NEXT_PUBLIC_SITE.createCheckoutSessionForGiftApiUrl,
        data: {
          price: priceId,
          email: email,
        },
      })
      const stripe = await getStripe(accountId, stripePublishableKey, loadStripe)
      if (stripe) {
        stripe.redirectToCheckout({ sessionId })
      } else {
        throw new Error('Failed to load stripe')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message =
        typeof error === 'object' && error?.cause?.name === 'USER_ALREADY_EXISTS'
          ? 'Subscription with email already exists.'
          : `An error occurred. Please try again later or contact customer support${customerServiceNumber ? `: ${customerServiceNumber}` : ''}.`
      setErrorMsg(message)
      setErrorModalIsOpen(true)
    } finally {
      setCallInProgress(false)
    }
  }, [])

  const onClickPurchaseGiftPlan = useCallback(() => {
    if (userEmail) {
      purchaseGiftPlan({ email: userEmail })
    } else {
      setUserInfoModalIsOpen(true)
    }
  }, [userEmail, purchaseGiftPlan, setUserInfoModalIsOpen])

  return (
    <>
      <div className={fixedBannerCTA}>
        <Text variant="overline" isLink={true} onClick={onClickPurchaseGiftPlan}>
          Give {publicationName}
        </Text>
      </div>
      <div className={container}>
        <CustomFlex
          direction="column"
          gap={{
            base: '2',
            bp1: '4',
            bp2: '6',
            bp3: '8',
          }}>
          <Text className={bestValue} variant="body2">
            Best Value
          </Text>
          <Text variant="body2">{name}</Text>
          {subtitle && <Text variant="body1">{subtitle}</Text>}
          <Text variant="h5" css={{ mt: '$2' }}>
            {dollarString.format(priceInCents / 100)} for {monthsGifted} months
          </Text>
          <Box
            className={buttonContainer({
              hasMarginBottom: !!(planSummary || (benefits && benefits.length)),
            })}>
            <Button
              variant="secondary"
              label="Give this gift"
              css={{
                mx: '$auto',
              }}
              onClick={onClickPurchaseGiftPlan}
            />
          </Box>
          {planSummary && (
            <>
              <CustomDivider
                orientation="horizontal"
                size="small"
                css={{ color: '$gs6' }}
                className={contentContainer}
              />
              <Text variant="body2" className={contentContainer}>
                {planSummary}
              </Text>
            </>
          )}
          {benefits && !!benefits.length && (
            <>
              <CustomDivider
                orientation="horizontal"
                size="small"
                css={{ color: '$gs6' }}
                className={contentContainer}
              />
              <WysiwygReader
                initialValue={benefits}
                className={contentContainer}
                css={{
                  '& p': {
                    _before: { content: '"✓ "' },
                  },
                  textWrap: 'pretty',
                  textAlign: 'left',
                }}
              />
            </>
          )}
        </CustomFlex>
      </div>
      <GetUserEmail
        isOpen={userInfoModalIsOpen}
        setIsOpen={setUserInfoModalIsOpen}
        onSubmitCallback={purchaseGiftPlan}
        callInProgress={callInProgress}
      />
      <DisplayErrorToUser errorMsg={errorMsg} isOpen={errorModalIsOpen} setIsOpen={setErrorModalIsOpen} />
    </>
  )
}

export const headerHeight = '[50px]'

const buttonContainer = cva({
  base: {
    textAlign: 'center',
  },
  variants: {
    hasMarginBottom: {
      true: {
        mb: '$4',
        bp1: {
          mb: '$6',
        },
        bp2: {
          mb: '$8',
        },
        bp3: {
          mb: '$10',
        },
      },
      false: {},
    },
  },
})

const fixedBannerCTA = css({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '50px', // linked to index.tsx
  zIndex: '1',
  backgroundColor: '$gs12',
  color: '$gs1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const dollarString = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

const container = css({
  textAlign: 'center',
  position: 'relative',
  borderColor: '$sec',
  borderWidth: '$3',
  backgroundColor: '$gs1',
  borderRadius: '$3',
  mx: '$auto',
  mt: '80px',
  mb: '100px',
  px: '40px',
  py: '60px',
  bp1: {
    width: '540px',
    py: '80px',
    px: '100px',
  },
  bp2: {
    width: '640px',
    py: '100px',
    px: '140px',
  },
  bp3: {
    width: '850px',
    py: '120px',
    px: '214px',
  },
})

const contentContainer = css({
  maxWidth: '260px',
  mx: '$auto',
  bp1: {
    maxWidth: '270px',
  },
  bp2: {
    maxWidth: '340px',
  },
  bp3: {
    maxWidth: '422px',
  },
})

const bestValue = css({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgColor: '$gs12',
  color: '$gs1',
  borderRadius: '$5',
  py: '$2',
  px: '$3',
})
