'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import giftImg from '@next-public-site/public/_static/gift.png'
import { useSession } from 'next-auth/react'
import { BlockPubGiftCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { css, cva } from 'styled-system/css'

import { WysiwygElement } from '@models/wysiwyg-types'

import { BlockViewProps } from '../../../_utils/typescript-utils'
import { GiftPlan } from './GiftPlan'
import { StarredPlan } from './StarredPlan'
import { SuccessOrFailModal } from './SuccessOrFailModal'

export type GiftPlanUI = {
  planSummary?: string
  benefits?: WysiwygElement[]
  name?: string
  subtitle?: string
  planPrice: number
  monthsGifted: number
}

export const BlockPubGift: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubGiftCustomPageData }>
> = props => {
  const { data: session, status } = useSession()

  const {
    headerLogoSrc,
    headerLogoWidth,
    headerLogoHeight,
    showHeaderGIF,
    headerTitle,
    headerSubtitle,
    disclaimer,
    plansWithGiftsPrices,
    starredGiftPlan,
    stripePublishableKey,
    customerServiceNumber,
    customerServiceNumberURI,
  } = props.blockCustomData

  const logoVariant =
    headerLogoHeight === headerLogoWidth
      ? 'square'
      : (headerLogoWidth || 0) > (headerLogoHeight || 0)
        ? 'landscape'
        : 'portrait'

  const userEmail = session?.user?.email

  return (
    <Gutter
      className={gutterStyled({ fixedBannerIsVisible: !!starredGiftPlan })}
      data-sho-block-name="BlockPubGift"
      data-sho-block-id={props.blockId}>
      {!plansWithGiftsPrices?.length && !starredGiftPlan?.giftProductId ? (
        <CustomFlex direction={'column'} gap="5" align={'center'}>
          <h1>
            <Text variant="h3">Gift Page</Text>
          </h1>
          <div>
            <Image
              //   sizes="(min-width: 77.5em) 360px, (min-width: 46.875em) 280px, (min-width: 37.5em) 240px, 195px"
              src={giftImg}
              alt="gift"
              width={500}
              height={500}
              className={giftGifStyle}
              unoptimized={true}
            />
          </div>
          <Text variant="body1">
            Gift options aren’t available at the moment, but stay tuned – exciting options may be coming your way soon!
          </Text>
        </CustomFlex>
      ) : (
        <>
          <CustomFlex direction={'column'} gap="5" css={topSection} align={'center'}>
            {headerLogoSrc && headerLogoWidth && headerLogoHeight && (
              <Link href="/" prefetch={false}>
                <ShoImage
                  src={headerLogoSrc}
                  width={headerLogoWidth}
                  height={headerLogoHeight}
                  alt="header logo"
                  className={logoStyle({
                    variant: logoVariant,
                  })}
                  sizes="50vw"
                />
              </Link>
            )}
            {showHeaderGIF && (
              <div>
                <Image
                  //   sizes="(min-width: 77.5em) 360px, (min-width: 46.875em) 280px, (min-width: 37.5em) 240px, 195px"
                  src={giftImg}
                  alt="gift"
                  width={500}
                  height={500}
                  className={giftGifStyle}
                  unoptimized={true}
                />
              </div>
            )}
            {headerTitle && (
              <h1>
                <Text variant="h4">{headerTitle}</Text>
              </h1>
            )}
            {headerSubtitle && <Text variant="body1">{headerSubtitle}</Text>}
          </CustomFlex>
          {starredGiftPlan && (
            <>
              <StarredPlan
                {...starredGiftPlan}
                stripePublishableKey={stripePublishableKey}
                userEmail={userEmail}
                customerServiceNumber={customerServiceNumber}
              />
              <Text variant="h5" css={{ textAlign: 'center' }} oll={false}>
                Additional Gift Options:
              </Text>
            </>
          )}
          <CustomGrid
            css={{
              mt: '$6',
              justifyItems: 'center',
              bp1: {
                mt: '$8',
              },
              bp3: {
                justifyItems: plansWithGiftsPrices?.length === 3 ? 'unset' : 'center',
                mt: '$10',
              },
            }}
            columns={{
              base: '1',
              bp3: plansWithGiftsPrices?.length === 1 ? '1' : plansWithGiftsPrices?.length === 2 ? '2' : '3',
            }}
            gap="small">
            {plansWithGiftsPrices?.map(plan => {
              return (
                <GiftPlan
                  planWithGiftsPrices={plan}
                  totalNumberOfPlans={plansWithGiftsPrices.length === 1 ? 1 : plansWithGiftsPrices.length === 2 ? 2 : 3}
                  userEmail={userEmail}
                  stripePublishableKey={stripePublishableKey}
                  customerServiceNumber={customerServiceNumber}
                />
              )
            })}
          </CustomGrid>
          {disclaimer && (
            <WysiwygReader
              initialValue={disclaimer}
              css={{
                mt: '$15',
                maxWidth: '700px',
                mx: '$auto',
                '& p[data-wisiwig-body1="true"]': {
                  fontSize: '$caption',
                  fontFamily: '$caption',
                  lineHeight: '$caption',
                },
              }}
            />
          )}
          <SuccessOrFailModal customerServiceNumber={customerServiceNumber} />
        </>
      )}
    </Gutter>
  )
}

const giftGifStyle = css({
  height: 'auto',
  width: '195px',
  bp1: {
    width: '240px',
  },
  bp2: {
    width: '280px',
  },
  bp3: {
    width: '360px',
  },
})

const topSection = {
  textAlign: 'center',
  mt: '15px',
  mb: '55px',
  bp1: {
    mt: '50px',
    mb: '105px',
  },
  bp2: {
    mt: '70px',
    mb: '125px',
  },
  bp3: {
    mt: '100px',
    mb: '165px',
  },
} as const

const logoStyle = cva({
  base: {
    objectFit: 'contain',
    width: 'auto',
  },
  variants: {
    variant: {
      square: {
        height: '50px',
        bp2: {
          height: '60px',
        },
        bp3: {
          height: '70px',
        },
      },
      landscape: {
        height: '24px',
        bp2: {
          height: '28px',
        },
        bp3: {
          height: '34px',
        },
      },
      portrait: {
        height: '40px',
        bp2: {
          height: '48px',
        },
        bp3: {
          height: '56px',
        },
      },
    },
  },
})

const gutterStyled = cva({
  base: {
    backgroundColor: '$gs5',
    pb: '$10',
  },
  variants: {
    fixedBannerIsVisible: {
      true: {
        marginTop: '50px', // height linked to StarredPlan.tsx
      },
    },
  },
})
