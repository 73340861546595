'use client'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@design-system/src/components/Box'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { useSession } from 'next-auth/react'
import React, { useCallback, useEffect, useRef } from 'react'
import { css } from 'styled-system/css'
import { SystemStyleObject } from 'styled-system/types'

import { AdvertisementSystem, CustomAd } from '@models/types'

const DIV_ID_DEBUG = '' // 'div-gpt-ad-hero-0'

export type GoogleAdType = 'vertical' | 'horizontal'

export type GoogleAdProps = {
  /**
   * Format of the ad. Defaults to "auto"
   */
  dataAdFormat?: string

  /**
   * If data is null width. Defaults to "true".
   */
  dataFullWidth?: boolean

  /**
   * Type of Google Ad ( Vertical or Horizontal )
   */
  type: GoogleAdType

  /**
   * css customization
   */
  css?: SystemStyleObject

  /**
   * Custom ads which can be used to override the default google ads
   */
  customAds?: CustomAd

  /**
   * Sizes attribute for the image
   */
  sizes?: string

  /**
   * Info for Google Tag Manager or Google Tag Manager 360
   */
  gtmUnitCode?: string

  gtmSizes?: [number, number][]

  gtmMinWidth?: number

  gtmMinHeight?: number

  gtmDivId?: string

  swapAdAtInterval?: boolean

  adSystem: AdvertisementSystem

  // if Google Ad Manager is used and no ads are displayed, the div with this id will be hidden
  collapsableId?: string

  /**
   * If true, the ad will have a fixed height
   * This will remove CLS issues
   */
  noCLS?: boolean
  alternativeGtmCustomAd?: CustomAd
}

const REFRESH_VALUE = 'true'
const SECONDS_TO_WAIT_AFTER_VIEWABILITY = 5
let refreshIntervalId: NodeJS.Timeout | null = null

const AD_MOBILE_MAX_WIDTH = 400
const AD_TABLET_MAX_WIDTH = 728
const AD_LAPTOP_MAX_WIDTH = 970
const AD_DESKTOP_MAX_WIDTH = 1200
const AD_LARGE_DESKTOP_MAX_WIDTH = 1920

export const GoogleAd: React.FC<GoogleAdProps> = ({
  dataAdFormat = 'auto',
  dataFullWidth = true,
  type = 'horizontal',
  css: cssProp = {},
  customAds,
  sizes = '(max-width: 77.5em) 100vw, 1200px',
  gtmUnitCode,
  gtmSizes,
  gtmMinWidth,
  gtmMinHeight,
  gtmDivId,
  swapAdAtInterval,
  adSystem,
  collapsableId,
  alternativeGtmCustomAd,
}) => {
  const gtmAlternativeGtmCustomerDivRef = useRef<HTMLDivElement>(null)
  const showGTM = adSystem === 'googleAdManager' && gtmUnitCode && gtmSizes && gtmMinWidth && gtmMinHeight && gtmDivId
  const responsiveStylesForGTM = getResponsiveStylesForGTM({
    gtmMinWidth,
    gtmMinHeight,
    gtmSizes,
    gtmDivId,
  })

  const session = useSession()

  //https://developers.google.com/publisher-tag/reference#googletag.events.SlotRenderEndedEvent
  const slotRenderedCallback = useCallback((event: any) => {
    // Slot is not empty, but no visible ad
    const slot = event.slot
    const slotId = slot.getSlotElementId()
    if (gtmDivId === DIV_ID_DEBUG) {
      console.info(
        DIV_ID_DEBUG,
        event,
        collapsableId,
        slotId,
        gtmUnitCode,
        gtmSizes,
        gtmMinWidth,
        gtmMinHeight,
        gtmDivId,
      )
    }

    if (slotId === gtmDivId) {
      // Since the event listener is triggered whenever an id (this one or anothor) is rendered, we need to check if the event is for this ad
      if (event.isEmpty) {
        if (collapsableId) {
          const el = window.document.getElementById(collapsableId)
          el?.style.setProperty('display', 'none')
          if (gtmDivId === DIV_ID_DEBUG) {
            console.info('The ad slot is empty, hiding the collapsable element.', {
              event,
              collapsableId,
              gtmUnitCode,
              gtmSizes,
              gtmMinWidth,
              gtmMinHeight,
              gtmDivId,
            })
          }
        }
        if (alternativeGtmCustomAd && gtmDivId) {
          const el = gtmAlternativeGtmCustomerDivRef.current
          if (el) {
            el.style.display = 'block'
          }
        }
      } else {
        if (gtmDivId === DIV_ID_DEBUG) {
          console.info('in else')
        }

        // You might need to define your own logic to check visibility
        const adElement = document.getElementById(slotId)
        const isAdVisible = adElement && adElement.offsetWidth > 0 && adElement.offsetHeight > 0

        if (!isAdVisible) {
          if (gtmDivId === DIV_ID_DEBUG) {
            console.info('The ad slot is not empty, but there is no visible ad.', {
              event,
              collapsableId,
              gtmUnitCode,
              gtmSizes,
              gtmMinWidth,
              gtmMinHeight,
              gtmDivId,
            })
          }
          if (collapsableId) {
            const el = window.document.getElementById(collapsableId)
            el?.style.setProperty('display', 'none')
          }
          if (alternativeGtmCustomAd && gtmDivId) {
            const el = gtmAlternativeGtmCustomerDivRef.current
            if (el) {
              el.style.display = 'block'
            }
          }
          // Perform additional actions as needed
        } else {
          if (slotId === DIV_ID_DEBUG) {
            console.info('An ad is visible in the slot.', {
              event,
              collapsableId,
              slotId,
              adElement,
              gtmUnitCode,
              gtmSizes,
              gtmMinWidth,
              gtmMinHeight,
              gtmDivId,
            })
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    if (session.status === 'loading') return
    // console.info('GoogleAd Load for', gtmDivId)
    const REFRESH_KEY = `REFRESH${gtmUnitCode}`
    let responsiveAdSlot: any
    if (showGTM) {
      const userSubscribed = session.data?.user?.stripeSubscriptions?.some(sub => sub.status === 'active')
      window.googletag = window.googletag || { cmd: [] }
      const googletag = window.googletag
      googletag.cmd.push(function () {
        responsiveAdSlot = googletag
          .defineSlot(gtmUnitCode, gtmSizes, gtmDivId)
          .setTargeting(REFRESH_KEY, swapAdAtInterval ? REFRESH_VALUE : 'false')
          .setTargeting('userSubscribed', userSubscribed ? 'true' : 'false')
          .setCollapseEmptyDiv(!!collapsableId)
          // TODO:
          // googletag.pubads().enableLazyLoad(); // Optional: Enable lazy only for ads below the fold
          .addService(googletag.pubads())
        googletag.pubads().addEventListener('slotRenderEnded', slotRenderedCallback)

        const {
          mobileToTabletAdSizes,
          tabletToLaptopAdSizes,
          laptopToDesktopAdSizes,
          mobileAdSizes,
          desktopToLargeDesktopAdSizes,
        } = createAdMappingArray(gtmSizes)

        // if (gtmDivId === DIV_ID_DEBUG) {
        //   console.info('Ad mapping', {
        //     mobileToTabletAdSizes,
        //     tabletToLaptopAdSizes,
        //     laptopToDesktopAdSizes,
        //     mobileAdSizes,
        //     desktopToLargeDesktopAdSizes,
        //   })
        // }

        // https://developers.google.com/publisher-tag/guides/ad-sizes
        if (
          laptopToDesktopAdSizes.length ||
          tabletToLaptopAdSizes.length ||
          mobileToTabletAdSizes.length ||
          mobileAdSizes.length ||
          desktopToLargeDesktopAdSizes.length
        ) {
          const mapping = googletag
            .sizeMapping()
            .addSize(
              [AD_LARGE_DESKTOP_MAX_WIDTH, 0],
              type === 'horizontal' ? desktopToLargeDesktopAdSizes : mobileAdSizes,
            ) // if vertical type, the UI will not be larger than 300px. So, remove all other sizes
            .addSize([AD_DESKTOP_MAX_WIDTH, 0], type === 'horizontal' ? laptopToDesktopAdSizes : mobileAdSizes) // if vertical type, the UI will not be larger than 300px. So, remove all other sizes
            .addSize([AD_LAPTOP_MAX_WIDTH, 0], type === 'horizontal' ? tabletToLaptopAdSizes : mobileAdSizes)
            .addSize([AD_TABLET_MAX_WIDTH, 0], type === 'horizontal' ? mobileToTabletAdSizes : mobileAdSizes)
            .addSize([0, 0], mobileAdSizes)
            .build()
          responsiveAdSlot.defineSizeMapping(mapping)
        }

        if (swapAdAtInterval) {
          // DOC: https://developers.google.com/publisher-tag/guides/control-ad-loading#refresh
          googletag.pubads().addEventListener('impressionViewable', function (event) {
            const slot = event.slot
            if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
              refreshIntervalId = setTimeout(function () {
                googletag.pubads().refresh([slot])
              }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000)
            }
          })
        }
        googletag.enableServices()
        googletag.cmd.push(function () {
          // console.info('Displaying ad slot', gtmDivId, responsiveAdSlot.getSlotId().getId())
          googletag.display(gtmDivId)
        })
      })
    }

    return () => {
      // destroy all ad slots
      const { googletag } = window
      if (googletag && googletag.cmd) {
        googletag.cmd.push(function () {
          // console.info('Destroying ad slot', responsiveAdSlot.getSlotId().getId())
          googletag.destroySlots([responsiveAdSlot])
        })
      }
      if (window.googletag && window.googletag.pubads) {
        window.googletag.pubads().removeEventListener('slotRenderEnded', slotRenderedCallback)
      }
      if (refreshIntervalId) {
        clearTimeout(refreshIntervalId)
      }
    }
  }, [session])

  if (adSystem === 'custom' && customAds) {
    const imgHeight = customAds.imgHeight
    const imgWidth = customAds.imgWidth
    const imgSrc = customAds.imgSrc
    const imgHref = customAds.href

    if (imgSrc && imgHeight && imgWidth) {
      return (
        <Box className={css(googleAdContainer, cssProp)}>
          <Box css={{ textAlign: 'center' }}>
            {imgHref ? (
              <a href={imgHref} target="_blank">
                <ShoImage
                  src={imgSrc}
                  alt="custom ad"
                  width={imgWidth}
                  height={imgHeight}
                  imageCss={imgCss}
                  sizes={sizes}
                />
              </a>
            ) : (
              <ShoImage
                src={imgSrc}
                alt="custom ad"
                width={imgWidth}
                height={imgHeight}
                imageCss={imgCss}
                sizes={sizes}
              />
            )}
          </Box>
        </Box>
      )
    }
  }

  if (showGTM) {
    return (
      <Box css={{ position: 'relative' }}>
        {/* must write styles in style tag instead of in css() because dynamic values (ex: minWidth: `[${gtmMinWidth}px]`)
        wont's work with Panda. And passing in style doesn't allow media queries */}
        <style>{responsiveStylesForGTM}</style>
        <div
          id={gtmDivId}
          data-ad-unit={gtmUnitCode}
          className={css(
            {
              display: 'flex',
              justifyContent: 'center',
              // minWidth: `[${gtmMinWidth}px]`, // WONT WORK
              // minHeight: gtmMinHeight, // WONT WORK
            },
            cssProp,
          )}
          // style={{
          //   minWidth: `${gtmMinWidth}px`,
          //   minHeight: `${gtmMinHeight}px`,
          // }}
        />
        {!!(
          alternativeGtmCustomAd &&
          alternativeGtmCustomAd.imgSrc &&
          alternativeGtmCustomAd.imgWidth &&
          alternativeGtmCustomAd.imgHeight
        ) && (
          <Box css={alternativeGtmCustomAdConstainerStyle} ref={gtmAlternativeGtmCustomerDivRef}>
            {alternativeGtmCustomAd?.href ? (
              <a href={alternativeGtmCustomAd?.href} target="_blank">
                <ShoImage
                  src={alternativeGtmCustomAd?.imgSrc}
                  alt="custom ad"
                  width={alternativeGtmCustomAd?.imgWidth}
                  height={alternativeGtmCustomAd?.imgHeight}
                  sizes={sizes}
                  imageCss={alternativeGtmCustomAdImgStyle}
                />
              </a>
            ) : (
              <ShoImage
                src={alternativeGtmCustomAd?.imgSrc}
                alt="custom ad"
                width={alternativeGtmCustomAd?.imgWidth}
                height={alternativeGtmCustomAd?.imgHeight}
                sizes={sizes}
                imageCss={alternativeGtmCustomAdImgStyle}
              />
            )}
          </Box>
        )}
      </Box>
    )
  }

  return null
}

type SizeArr = [number, number][]
const createAdMappingArray = (
  gtmSizes: [number, number][],
): {
  mobileAdSizes: SizeArr
  mobileToTabletAdSizes: SizeArr
  tabletToLaptopAdSizes: SizeArr
  laptopToDesktopAdSizes: SizeArr
  desktopToLargeDesktopAdSizes: SizeArr
} => {
  let mobileAdSizes: SizeArr = []
  let mobileToTabletAdSizes: SizeArr = []
  let tabletToLaptopAdSizes: SizeArr = []
  let laptopToDesktopAdSizes: SizeArr = []
  let desktopToLargeDesktopAdSizes: SizeArr = []

  gtmSizes.forEach(size => {
    const adWidth = size[0]
    if (adWidth > AD_LARGE_DESKTOP_MAX_WIDTH) {
      // exclude add
    } else if (adWidth > AD_DESKTOP_MAX_WIDTH) {
      desktopToLargeDesktopAdSizes.push(size)
    } else if (adWidth > AD_LAPTOP_MAX_WIDTH) {
      laptopToDesktopAdSizes.push(size)
    } else if (adWidth > AD_TABLET_MAX_WIDTH) {
      tabletToLaptopAdSizes.push(size)
    } else if (adWidth > AD_MOBILE_MAX_WIDTH) {
      mobileToTabletAdSizes.push(size)
    } else mobileAdSizes.push(size)
  })

  if (!mobileToTabletAdSizes.length) mobileToTabletAdSizes = mobileAdSizes
  // Now, showing mobile/tablet ads on laptop if there are no ads for mobile/tablet
  if (!tabletToLaptopAdSizes.length) tabletToLaptopAdSizes = mobileToTabletAdSizes
  // Now, showing laptop ads on desktop if there are no ads for laptop
  if (!laptopToDesktopAdSizes.length) laptopToDesktopAdSizes = tabletToLaptopAdSizes
  // Now, showing desktop ads on large desktop if there are no ads for desktop
  if (!desktopToLargeDesktopAdSizes.length) desktopToLargeDesktopAdSizes = laptopToDesktopAdSizes

  return {
    mobileAdSizes,
    mobileToTabletAdSizes,
    tabletToLaptopAdSizes,
    laptopToDesktopAdSizes,
    desktopToLargeDesktopAdSizes,
  }
}

const getResponsiveStylesForGTM = ({
  gtmMinWidth,
  gtmMinHeight,
  gtmSizes,
  gtmDivId,
}: {
  gtmMinWidth: number | undefined
  gtmMinHeight: number | undefined
  gtmSizes: [number, number][] | undefined
  gtmDivId: string | undefined
}): string => {
  if (gtmSizes) {
    const {
      mobileAdSizes,
      mobileToTabletAdSizes,
      tabletToLaptopAdSizes,
      laptopToDesktopAdSizes,
      desktopToLargeDesktopAdSizes,
    } = createAdMappingArray(gtmSizes)
    let largestMobileAdSizeHeight = mobileAdSizes.reduce((max, size) => Math.max(max, size[1]), 0)
    let largestTabletAdSizeHeight = mobileToTabletAdSizes.reduce((max, size) => Math.max(max, size[1]), 0)
    let largestLaptopAdSizeHeight = tabletToLaptopAdSizes.reduce((max, size) => Math.max(max, size[1]), 0)
    let largestDesktopAdSizeHeight = laptopToDesktopAdSizes.reduce((max, size) => Math.max(max, size[1]), 0)
    let largestLargeDesktopAdSizeHeight = desktopToLargeDesktopAdSizes.reduce((max, size) => Math.max(max, size[1]), 0)

    if (!largestLargeDesktopAdSizeHeight) largestLargeDesktopAdSizeHeight = largestDesktopAdSizeHeight
    if (!largestDesktopAdSizeHeight) largestDesktopAdSizeHeight = largestLaptopAdSizeHeight
    if (!largestLaptopAdSizeHeight) largestLaptopAdSizeHeight = largestTabletAdSizeHeight
    if (!largestTabletAdSizeHeight) largestTabletAdSizeHeight = largestMobileAdSizeHeight

    return `
    #${gtmDivId} {
      min-width: ${gtmMinWidth}px;
      min-height: ${largestMobileAdSizeHeight}px;
      ${largestMobileAdSizeHeight === 0 && 'display: none;'}
    }
    @media (min-width: ${AD_TABLET_MAX_WIDTH}px) {
      #${gtmDivId} {
        min-height: ${largestTabletAdSizeHeight}px;
       display:  ${largestTabletAdSizeHeight === 0 ? 'none' : 'flex'};
      }
    }
    @media (min-width: ${AD_LAPTOP_MAX_WIDTH}px) {
      #${gtmDivId} {
        min-height: ${largestLaptopAdSizeHeight}px;
        display:  ${largestLaptopAdSizeHeight === 0 ? 'none' : 'flex'};
      }
    }
    @media (min-width: ${AD_DESKTOP_MAX_WIDTH}px) {
      #${gtmDivId} {  
        min-height: ${largestDesktopAdSizeHeight}px;
        display:  ${largestDesktopAdSizeHeight === 0 ? 'none' : 'flex'};
      }
    }
    @media (min-width: ${AD_LARGE_DESKTOP_MAX_WIDTH}px) {
      #${gtmDivId} {
        min-height: ${largestLargeDesktopAdSizeHeight}px;
        display:  ${largestLargeDesktopAdSizeHeight === 0 ? 'none' : 'flex'};
      }
    }
    `
  }
  return `
  #${gtmDivId} {
    min-width: ${gtmMinWidth}px;
    min-height: ${gtmMinHeight}px;
  }
  `
}

const alternativeGtmCustomAdImgStyle = {
  objectFit: 'contain',
  height: '$full',
  width: '[auto]',
  mx: '$auto',
} as const

const alternativeGtmCustomAdConstainerStyle = {
  display: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '$full',
  height: '$full',
  p: '$2',
} as const

const imgCss = {
  mx: '$auto',
  maxHeight: '60vh',
  width: '$auto',
  height: '$auto',
  maxWidth: '$full',
  display: 'block',
} as const

const googleAdContainer = {
  bgColor: '$gs3',
  width: '$full',
} as const
