'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { useSession } from 'next-auth/react'
import type { BlockPubArticleBodyCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC } from 'react'

import { WysiwygElement } from '@models/wysiwyg-types'

import { isUserSubscribedToNewsletter } from '../../../_utils/publication-utils'
import { EmailCapture } from '../../EmailCapture'

export const ArticleText: FC<{
  articleMidpoint: number
  articleContent: WysiwygElement[]
  articleType: BlockPubArticleBodyCustomPageData['articleType']
  emailCaptureConfig: BlockPubArticleBodyCustomPageData['emailCaptureConfig']
  newsletterId: BlockPubArticleBodyCustomPageData['emailCaptureConfig']['newsletterId']
}> = ({ articleContent, articleMidpoint, articleType, emailCaptureConfig, newsletterId }) => {
  const { data: session, status } = useSession()
  const isSubscribedUser = isUserSubscribedToNewsletter(status, session, newsletterId)

  // We don't want to inject the email capture form right after a heading
  let articleInjectionPoint =
    articleContent[articleMidpoint - 1]?.type === 'h3' || articleContent[articleMidpoint - 1]?.type === 'h5'
      ? articleMidpoint - 1
      : articleMidpoint

  return !isSubscribedUser && articleType === 'default' && articleContent ? (
    newsletterId ? (
      <CustomFlex direction="column" gap={'10'}>
        <WysiwygReader initialValue={articleContent.slice(0, articleInjectionPoint)} css={wysiwygStylesOverride} />
        <Gutter css={{ bgColor: '$gs1', boxShadow: '[0rem 0.1875rem 0.4625rem 0.0375rem {colors.$b11}]' }}>
          <EmailCapture {...emailCaptureConfig} session={session} status={status} variation="light" />
        </Gutter>
        <WysiwygReader initialValue={articleContent.slice(articleInjectionPoint)} css={wysiwygStylesOverride} />
      </CustomFlex>
    ) : (
      <WysiwygReader initialValue={articleContent} />
    )
  ) : articleContent ? (
    <WysiwygReader initialValue={articleContent} />
  ) : null
}

const wysiwygStylesOverride = {
  '& p[data-wisiwig-body1]': {
    color: '$b2',
  },
} as const
