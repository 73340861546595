'use client'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon, CircleIcon } from '@radix-ui/react-icons'
import { forwardRef, useState } from 'react'
import { css, cva, cx } from 'styled-system/css'
import { SystemStyleObject } from 'styled-system/types'

import { TypographyVariants } from '../utils/typescript-utils'
import { Box } from './Box'
import { Icon } from './Icon'
import { Text } from './Text'

export interface CheckboxProps {
  /**
   * control the checked state of the checkbox
   * @default false
   * */
  checked?: boolean

  /**
   * Give css classname to check box.
   */
  className?: string

  /**
   * Give css property to checkbox.
   */
  css?: SystemStyleObject

  /**
   * Default checked for checkbox.
   */
  defaultChecked?: boolean

  /**
   * Type of icon to be used.
   * @default 'check'
   * */
  iconType?: 'checkmark' | 'indeterminate'

  /**
   *  Checkbox title label.
   */
  label?: string

  /**
   * Checkbox state for checked state.
   */
  onChange?: (isChecked: boolean) => void

  /**
   * Checkbox state whether it is active or disabled.
   */
  state?: 'active' | 'disabled'

  /**
   * Give checkbox label.
   */
  text?: string

  /**
   *  Set the font size of inputting text.
   */
  textVariant?: TypographyVariants
}

const DEFAULT_ICON_TYPE = 'checkmark'

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>((props, forwardRef) => {
  const {
    className,
    defaultChecked,
    css: cssProp = {},
    state,
    text,
    onChange,
    label,
    checked,
    iconType = DEFAULT_ICON_TYPE,
    textVariant = 'body1',
  } = props
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked ? defaultChecked : false)

  const handleChange = (checked: CheckboxPrimitive.CheckedState) => {
    if (checked !== 'indeterminate') {
      setIsChecked(checked)
      if (onChange) {
        onChange(checked)
      }
    }
  }

  const controlledChecked = checked !== undefined ? checked : isChecked
  const reactIcon = iconType === 'checkmark' ? <CheckIcon /> : <CircleIcon />

  return (
    <Box
      className={cx(css(cssProp), className)}
      ref={forwardRef}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}>
      {label && (
        <Text css={css.raw({ mb: '$2', color: '$gs10' })} variant="overline">
          {label}
        </Text>
      )}
      <div className={css(checkboxContainer)}>
        <CheckboxPrimitive.Root
          className={styledCheckbox({
            state,
            checked: controlledChecked,
            iconType,
          })}
          onCheckedChange={handleChange}
          checked={controlledChecked}
          defaultChecked={defaultChecked}
          disabled={state === 'disabled'}>
          {controlledChecked && (
            <Icon
              reactIcon={reactIcon}
              pointer={true}
              size={'18'}
              className={iconStyled({
                state,
                iconType,
              })}
            />
          )}
        </CheckboxPrimitive.Root>
        {text && (
          <Text
            variant={textVariant}
            className={checkboxLabel({
              state,
            })}>
            {text}
          </Text>
        )}
      </div>
    </Box>
  )
})

Checkbox.displayName = 'Checkbox'

const iconStyled = cva({
  base: {
    color: '$btnSecText !important',
    borderWidth: '$1',
    borderColor: '$gs12',
    borderStyle: 'solid',
  },
  variants: {
    state: {
      active: {
        cursor: 'pointer',
      },
      disabled: {
        cursor: 'not-allowed',
      },
    },
    iconType: {
      indeterminate: {
        borderRadius: 'full',
      },
      checkmark: {
        borderRadius: '0',
      },
    },
  },
  defaultVariants: {
    state: 'active',
    iconType: DEFAULT_ICON_TYPE,
  },
})

const styledCheckbox = cva({
  base: {
    flexShrink: 0,
    all: 'unset',
    boxSizing: 'border-box',
    userSelect: 'none',
    _before: {
      boxSizing: 'border-box',
    },
    _after: {
      boxSizing: 'border-box',
    },
    alignItems: 'center',
    appearance: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    lineHeight: '[1em]',
    margin: '0',
    cursor: 'pointer',
    outline: 'none',
    padding: '0',
    width: '$5',
    height: '$5',
  },
  variants: {
    state: {
      active: {
        backgroundColor: '$btnSecText',
        borderWidth: '[2px]',
        borderColor: '$gs12',
        borderStyle: 'solid',
      },
      disabled: {
        backgroundColor: '$btnSecText',
        borderWidth: '[2px]',
        borderColor: '$gs8',
        cursor: 'not-allowed',
        borderStyle: 'solid',
      },
    },
    checked: {
      true: {
        backgroundColor: '$sec',
        borderStyle: 'none',
      },
      false: {
        backgroundColor: '$gs1',
      },
    },
    iconType: {
      indeterminate: {
        borderRadius: 'full',
      },
      checkmark: {
        borderRadius: '0',
      },
    },
  },
  compoundVariants: [
    {
      state: 'active',
      checked: false,
      css: {
        '&:hover': {
          transitionProperty: 'all',
          transitionDuration: '$fast',
          transitionTimingFunction: 'in-out',
          borderColor: '$sec',
        },
      },
    },
    {
      state: 'disabled',
      checked: true,
      css: {
        backgroundColor: '$gs3',
      },
    },
    {
      state: 'disabled',
      checked: false,
      css: {
        backgroundColor: '$gs3',
      },
    },
  ],
  defaultVariants: {
    state: 'active',
    iconType: DEFAULT_ICON_TYPE,
  },
})

const checkboxContainer = css.raw({
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
})

const checkboxLabel = cva({
  variants: {
    state: {
      active: {
        color: '$gs12',
      },
      disabled: {
        color: '$gs8',
        _hover: {
          color: '$gs8',
        },
      },
    },
  },
  defaultVariants: {
    state: 'active',
  },
})
