export const truncateText = (text: string, limit: number) =>
  (text ? text.length : 0) > limit ? `${text.substring(0, limit)}...` : text

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const DEFAULT_PUBLIC_CHATBOT_GREETING = 'Hello, how can I help you today?'

export const sanitizeUsername = (username: string): string => {
  let toReturn = ''
  // allows users to type emails without regard to casing
  toReturn = username.toLowerCase()
  // allows users to copy paste and not worry about empty space before, after, or even within username
  toReturn = toReturn.replace(' ', '')
  return toReturn
}

export const sanitizePassword = (password: string): string => password.replace(' ', '')
