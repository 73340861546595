'use client'

import { Accordion, AccordionProps } from '@design-system/src/components/Accordion'
import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { getStripe } from '@design-system/src/utils/stripe-utils'
import { validEmail } from '@design-system/src/utils/validation-utils'
import giftImg from '@next-public-site/public/_static/gift.png'
import { loadStripe } from '@stripe/stripe-js/pure'
import { CreateCheckoutSessionBody } from 'app/api/stripe/create-checkout-session/route'
import { useSession } from 'next-auth/react'
import { BlockPubGiftRedeemCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import { css } from 'styled-system/css'
import { Divider } from 'styled-system/jsx'

import { isUserSubscribedToPublication } from '../../_utils/publication-utils'
import { postData } from '../../_utils/rest-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'

type FormInput = {
  code: string
  productId: string
  email: string
}

export const BlockPubGiftRedeem: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubGiftRedeemCustomPageData }>
> = props => {
  const { data: session, status } = useSession()
  const { stripePublishableKey, stripePlans, stripeSupportPlans, connectedSubscriptionSystem, customerServiceNumber } =
    props.blockCustomData
  const searchParams = useSearchParams()
  const code = searchParams.get('code')
  const productId = searchParams.get('productId')
  const transactionFailed = searchParams.get('status')
  const userEmail = session?.user?.email
  const [errorMsg, setErrorMsg] = useState(
    transactionFailed
      ? `Sorry, we failed to processs your transaction. Please try again or contact customer service.`
      : '',
  )
  const [callInProgress, setCallInProgress] = useState(false)
  const userIsSubscribed = isUserSubscribedToPublication({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormInput>({
    mode: 'onSubmit',
    defaultValues: getFormDefaultValues({ code, productId, email: userEmail }),
  })

  useEffect(() => {
    if (userEmail) {
      setValue('email', userEmail, { shouldDirty: false })
    }
  }, [userEmail])

  const onSubmit = useCallback(async (data: FormInput) => {
    console.log(data)
    if (!productId) {
      setErrorMsg('No priceId found')
      throw new Error('No priceId found')
    }
    try {
      setCallInProgress(true)
      setErrorMsg('')
      const priceId = stripePlans.find(plan => plan.productId === productId)?.stripePriceIdMonthly
      if (!priceId) {
        setErrorMsg('No priceId found')
        throw new Error('No priceId found')
      }
      const { accountId, sessionId } = await postData<
        CreateCheckoutSessionBody,
        {
          sessionId: string
          accountId: string
        }
      >({
        url: API_ROUTES_NEXT_PUBLIC_SITE.createSubscriberWithStripeAccountApiUrl,
        data: {
          price: priceId,
          email: data.email,
          coupon: data.code,
          from: 'gift',
        },
      })
      const stripe = await getStripe(accountId, stripePublishableKey, loadStripe)
      if (stripe) {
        stripe.redirectToCheckout({ sessionId })
      } else {
        throw new Error('Failed to load stripe')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message =
        typeof error === 'object' && error?.cause?.name === 'USER_ALREADY_EXISTS'
          ? 'Subscription with email already exists.'
          : `We were unable to redeem your gift. Please try again later or contact customer support${customerServiceNumber ? `: ${customerServiceNumber}` : ''}.`
      setErrorMsg(message)
    } finally {
      setCallInProgress(false)
    }
  }, [])

  return (
    <Gutter
      className={css({ bgColor: '$gs3' })}
      data-sho-block-name="BlockPubGiftRedeem"
      data-sho-block-id={props.blockId}>
      <h1>
        <Text variant="h2" css={headerStyle} oll={false}>
          Redeem Gift
        </Text>
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className={formContainer}>
        <CustomFlex direction="column" gap="3">
          <CustomFlex justify="center">
            <Image
              // sizes="(min-width: 77.5em) 360px, (min-width: 46.875em) 280px, (min-width: 37.5em) 240px, 195px"
              src={giftImg}
              alt="gift"
              width={500}
              height={500}
              unoptimized={true}
              className={giftGifStyle}
            />
          </CustomFlex>
          <Controller
            name="email"
            control={control}
            disabled={!!userEmail}
            rules={{
              required: 'Your Email is required.',
              pattern: {
                value: validEmail,
                message: 'Please enter a valid email address.',
              },
            }}
            render={({ field }) => (
              <Textfield
                {...field}
                fullWidth={true}
                reactFormErrors={errors}
                inputVariant="body2"
                floatingLabel="* Email Address "
                placeholder="john.doe@example.com"
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            rules={{ required: 'Coupon is required.' }}
            render={({ field }) => (
              <Textfield
                {...field}
                inputVariant="body2"
                fullWidth={true}
                reactFormErrors={errors}
                floatingLabel="* Coupon Code "
                placeholder="promo_abcdefghijklmop"
              />
            )}
          />
        </CustomFlex>
        <Button
          buttonType="submit"
          variant="primary"
          label="Redeem Gift"
          fullWidth={true}
          css={{
            mt: '$6',
          }}
          disabled={userIsSubscribed}
          buttonState={callInProgress ? 'waiting' : 'default'}
        />
        {userIsSubscribed && (
          <CustomFlex
            css={{
              py: '$2',
              px: '$4',
              bgColor: '$war',
              color: '$warText',
              mt: '$2',
            }}>
            <Text variant="caption">
              You are already subscribed. Gifts can only be applied to accounts without an active subscription.
            </Text>
          </CustomFlex>
        )}
        {errorMsg && (
          <CustomFlex
            css={{
              py: '$2',
              px: '$4',
              bgColor: '$ale',
              color: '$aleText',
              mt: '$2',
            }}>
            <Text variant="caption">{errorMsg}</Text>
          </CustomFlex>
        )}
      </form>
      <Divider css={dividerStyle} />
      <Accordion
        maxWidth={800}
        panels={getAccordionPanels({ customerServiceNumber })}
        variant="color"
        type="multiple"
      />
    </Gutter>
  )
}

const getAccordionPanels = ({
  customerServiceNumber,
}: {
  customerServiceNumber?: string
}): AccordionProps['panels'] => [
  {
    id: '1',
    panelHeader: 'I received a gift code, but I am already a subscriber. What should I do?',
    panelBody: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Only new subscribers can redeem a digital access code. You can give this code to a friend or family member.',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    panelHeader: 'If I lost my digital access code, what should I do?',
    panelBody: [
      {
        type: 'paragraph',
        children: [{ text: 'Please contact the organization that gave you the code.' }],
      },
    ],
  },
  {
    id: '3',
    panelHeader: 'Whom do I contact for questions about my digital access code?',
    panelBody: [
      {
        type: 'paragraph',
        children: [
          { text: `Please contact customer service${customerServiceNumber ? `: ${customerServiceNumber}` : ''}.` },
        ],
      },
    ],
  },
]

const getFormDefaultValues = ({
  code,
  productId,
  email,
}: {
  code: string | null
  productId: string | null
  email: string | null | undefined
}): FormInput => ({ email: email ?? '', code: code ?? '', productId: productId ?? '' })

const headerStyle = {
  marginBottom: '$6',
  color: '$gs12',
  textAlign: 'center',
  bp1: {
    marginBottom: '$8',
  },
  bp2: {
    marginBottom: '$10',
  },
  bp3: {
    marginBottom: '$12',
  },
} as const

const dividerStyle = {
  my: '$10',
  bp1: { my: '$12' },
  bp2: { my: '$14' },
  bp3: { my: '$16' },
}

const formContainer = css({
  position: 'relative',
  backgroundColor: '$gs1',
  borderRadius: '$3',
  mx: '$auto',
  mt: '40px',
  mb: '100px',
  px: '40px',
  py: '50px',
  bp1: {
    width: '540px',
    mt: '50px',
    py: '60px',
    px: '100px',
  },
  bp2: {
    width: '640px',
    mt: '60px',
    py: '80px',
    px: '140px',
  },
  bp3: {
    width: '850px',
    mt: '80px',
    py: '120px',
    px: '214px',
  },
})

const giftGifStyle = css({
  height: 'auto',
  width: '55px',
  bp1: {
    width: '70px',
  },
  bp2: {
    width: '100px',
  },
  bp3: {
    width: '140px',
  },
})
