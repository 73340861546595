'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Icon } from '@design-system/src/components/Icon'
import { Spinner } from '@design-system/src/components/Spinner'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import {
  PROPAY_CREATE_SUBCRIPTION_RESPONSE_BODY_TYPE,
  PROPAY_CREATE_SUBSCRIPTION_ARGS_TYPE,
} from '@next-public-site/app/api/propay/propay-create-subscription/route'
import { Cross1Icon } from '@radix-ui/react-icons'
import { postData } from 'app/sites/[site]/[[...page]]/_utils/rest-utils'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next-nprogress-bar'
import { BlockPubSubscribeCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { useSearchParams } from 'next/navigation'
import React, { useCallback, useMemo, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE, PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'
import { css } from 'styled-system/css'

import { PropaySubscriptionPlan } from '@models/types'

import {
  getSubscriptionIdForPublication,
  getSubscriptionRecurrence,
  getUserSubscriptionPlan,
  isUserSubscribedToNewsletter,
  isUserSubscribedToPublication,
} from '../../../../_utils/publication-utils'
import { BlockViewProps } from '../../../../_utils/typescript-utils'
import { BlockModal } from '../../../BlockModal'
import { CancelSubscriptionModal } from '../SubscibeShared/CancelSubscriptionModal'
import { CancelationFormInput, CancelationReasonModal } from '../SubscibeShared/CancelationReasonModal'
import { ConfirmSubscriptionCancelModal } from '../SubscibeShared/ConfirmSubscriptionCancelModal'
import { PaymentPortalModal } from './PaymentPortalModal'

export const SubscribePropay: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubSubscribeCustomPageData
  }>
> = props => {
  const {
    description,
    subscribeToNewsletterApiUrl,
    newsletterId,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
  } = props.blockCustomData
  const searchParams = useSearchParams()
  const router = useRouter()
  const { data: session, status } = useSession()
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState(false)
  const [isCancelReasonModalOpen, setIsOpenCancelReasonModal] = useState(false)
  const [subscriptionCancelledModalOpen, setSubscriptionCancelledModalOpen] = useState(false)
  const [paymentPortalModalOpen, setPaymentPortalModalOpen] = useState(false)
  const [transactionIdForPaymentPortal, setTransactionIdForPaymentPortal] = useState<string>('')
  const [thankyouModalOpen, setThankyouModalOpen] = useState(false)
  const [feedbackFormReason, setFeedbackFormReason] = useState<CancelationFormInput>()
  const [errorMsg, setErrorMsg] = useState('')
  const subscribedPlanRecurrence = getSubscriptionRecurrence({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })

  const isUserSubscribed = isUserSubscribedToPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscriptionId = getSubscriptionIdForPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscribedUserPlan = getUserSubscriptionPlan({
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
  })
  const isUserSubscribedNewsLetter = isUserSubscribedToNewsletter(status, session, newsletterId)

  // if we have 3 plans, put first one in the middle, if we have 2 plans, put first one last
  const orderedPlans = useMemo<PropaySubscriptionPlan[]>(() => {
    const activePropayPlans = propayPlans.filter(plan => plan.status === 'active').slice(0, 3)
    return activePropayPlans.length === 3
      ? [activePropayPlans[1], activePropayPlans[0], activePropayPlans[2]]
      : activePropayPlans.length === 2
        ? [activePropayPlans[1], activePropayPlans[0]]
        : activePropayPlans
  }, [stripePlans, propayPlans])

  const handleSubscribeUserToPlan = async (email?: string) => {
    if (isProcessingPayment) return
    setIsProcessingPayment(true)
    setErrorMsg('')
    try {
      const res = await postData<PROPAY_CREATE_SUBSCRIPTION_ARGS_TYPE, PROPAY_CREATE_SUBCRIPTION_RESPONSE_BODY_TYPE>({
        data: {
          email: email || session?.user?.email,
        },
        url: API_ROUTES_NEXT_PUBLIC_SITE.createPropaySubscription,
      })
      if (res.subscriptionSuccess) {
        return router.push(PAGES_NEXT_PUBLIC_SITES.thankYou)
      } else {
        setErrorMsg(res.error?.message || 'Unable to subscribe user to plan. Please try again later.')
      }
    } catch (error: any) {
      console.error('error', error)
      setErrorMsg(error?.message || 'An error occured while subscribing. Please try again later.')
    } finally {
      setIsProcessingPayment(false)
    }
  }

  const handleOpenPaymentPortalModal = useCallback((transactionId: string) => {
    setTransactionIdForPaymentPortal(transactionId)
    setPaymentPortalModalOpen(true)
  }, [])

  return (
    <>
      {isProcessingPayment && (
        <div
          className={css({
            position: 'fixed',
            backgroundColor: '$b5',
            inset: '0',
            zIndex: '100000',
          })}>
          <div
            className={css({
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '100000',
              backgroundColor: '$gs3',
              p: '$6',
              borderRadius: '$3',
            })}>
            <CustomFlex direction="column" align={'center'} gap="2">
              <Text
                css={{
                  textWrap: 'pretty',
                  textAlign: 'center',
                }}>
                Please do not close this window. Your subscription is being processed.
              </Text>
              <Spinner />
            </CustomFlex>
          </div>
        </div>
      )}
      {errorMsg && (
        <Text
          variant="caption"
          css={{
            color: '$aleText',
            p: '$2',
            borderRadius: '$3',
            backgroundColor: '$ale',
            mt: '$6',
            width: ['fit-content'],
            mx: '$auto',
          }}>
          {errorMsg}
          <Icon
            reactIcon={<Cross1Icon />}
            size={'12'}
            css={{
              marginLeft: 'auto',
              cursor: 'pointer',
              color: '$gs1',
              borderWidth: '$1',
              borderStyle: 'solid',
              borderColor: '$gs1',
              borderRadius: '$3',
              p: '2px',
              ml: '$4',
              '&:hover': {
                opacity: '[0.8]',
                transition: '[opacity 0.2s ease-in-out]',
              },
            }}
            onClick={() => {
              setErrorMsg('')
            }}
          />
        </Text>
      )}
      <CustomFlex
        css={{ maxWidth: '[750px]', margin: 'auto', mb: '$5', px: '$8' }}
        direction="column"
        justify="center"
        align="center">
        <WysiwygReader
          initialValue={description}
          css={{
            color: '$gs12',
            '&p[data-wisiwig-body1="true"]': {
              color: '$gs11',
            },
            textAlign: 'center',
          }}
        />
      </CustomFlex>
      {/* <CustomGrid
        css={{
          justifyItems: 'center',
          bp3: {
            justifyItems: orderedPlans?.length === 1 ? 'center' : 'unset',
          },
        }}
        columns={{
          base: '1',
          bp3: orderedPlans?.length === 1 ? '1' : orderedPlans?.length === 2 ? '2' : '3',
        }}
        gap="small">
        {orderedPlans?.map((plan, index) => {
          return (
            <PropayPlanCard
              key={plan.name}
              isTopChoice={index === 1}
              isUserUnauthenticated={status === 'unauthenticated'}
              propaySubscriptionPlan={plan}
              toggleOptionFrequency={toggleOption ? 'yearly' : 'monthly'}
              userSubscriptionPlan={
                subscribedUserPlan?.system === 'PROPAY' ? subscribedUserPlan.subscription : undefined
              }
              userSubscriptionPlanReccurence={subscribedPlanRecurrence}
              handleSubscribeUserToPlan={handleSubscribeUserToPlan}
              handleOpenPaymentPortalModal={handleOpenPaymentPortalModal}
              totalNumberOfPlans={orderedPlans.length as 1 | 2 | 3}
              css={{
                order: index === 1 ? '[-1]' : index,
                bp1: {
                  order: index === 1 ? '[-1]' : index,
                  flex: index === 1 ? '[1 0 100%]' : '[none]',
                },
                bp3: {
                  order: index === 1 ? 1 : index,
                  flex: 'none',
                },
              }}
            />
          )
        })}
      </CustomGrid> */}

      {isUserSubscribed && status === 'authenticated' && searchParams.get('action') === 'manage-subscription' && (
        <Text
          variant="caption"
          css={{ color: '$pri', margin: 'auto', width: 'fit', mt: '$10', cursor: 'pointer' }}
          onClick={() => setOpenCancelSubscriptionModal(true)}>
          Want to cancel your subscription? Click Here.
        </Text>
      )}
      {paymentPortalModalOpen && (
        <PaymentPortalModal
          modalIsOpen={paymentPortalModalOpen}
          modalIsOpenChange={setPaymentPortalModalOpen}
          transactionId={transactionIdForPaymentPortal}
          setErrorMsg={setErrorMsg}
          handleSubscribeUserToPlan={handleSubscribeUserToPlan}
        />
      )}
      <CancelationReasonModal
        open={openCancelSubscriptionModal}
        setOpen={setOpenCancelSubscriptionModal}
        setIsCancelSubscriptionModalOpen={setIsOpenCancelReasonModal}
        setFeedbackFormReason={setFeedbackFormReason}
      />
      <CancelSubscriptionModal
        open={isCancelReasonModalOpen}
        setOpen={setIsOpenCancelReasonModal}
        cancelSubscriptionApiUrl={API_ROUTES_NEXT_PUBLIC_SITE.cancelPropaySubscription}
        setSubscriptionCancelledModalOpen={setSubscriptionCancelledModalOpen}
        subscriptionId={subscriptionId}
        feedbackFormReason={feedbackFormReason}
      />
      {isUserSubscribed && status === 'authenticated' && (
        <ConfirmSubscriptionCancelModal
          open={subscriptionCancelledModalOpen}
          setOpen={setSubscriptionCancelledModalOpen}
          subscribeToNewsletterApiUrl={subscribeToNewsletterApiUrl}
          setThankyouModalOpen={setThankyouModalOpen}
          newsletterId={newsletterId}
          subscribed={isUserSubscribedNewsLetter}
          userEmail={session?.user?.email}
        />
      )}
      <BlockModal
        openModal={thankyouModalOpen}
        onCloseModalCallback={setThankyouModalOpen}
        title="Thank You For Signing Up!">
        <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center' }}>
          You have successfully signed up for the Newsletter.
        </Text>
      </BlockModal>
    </>
  )
}
